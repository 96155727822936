import React from 'react';

const Divider = ({position, points}) => (
  <div className={`divider divider--${position}`}>
    <svg width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
      <polygon points={points} fill="#121310" />
    </svg>
  </div>
);

export default Divider;
