import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import logo from './assets/images/logo.svg';
import directory from './assets/images/directory2.png';
import search from './assets/images/directory-search.png';
import bio from './assets/images/bio3.png';
import stats from './assets/images/stats.png';
import studioCard from './assets/images/studio-card.png';
import triangles from './assets/images/triangles.svg';

import ContentSection from './components/ContentSection';
import CentreSection from './components/CentreSection';
import ShardGroup from './components/ShardGroup';
import { GROUP1, GROUP2, GROUP3, GROUP4, GROUP6 } from './components/ShardGroup/settings';

import './App.css';
import { COPY } from './copy';
import Divider from './components/Divider';

const App = () => (
  <div className="App">
    <div className='background'>
      <img src={triangles} alt="background" className="background__image" />
    </div>

    <div className='page'>
      <header className="App-header">
        <img src={logo} alt="logo" />
      </header>

      <ParallaxProvider scrollAxis='vertical'>
        <div className='fold'>
          <CentreSection section={COPY.fold} formId={1} />

          <ShardGroup items={GROUP1} />
        </div>

        <div className="section section--black">
          <Divider position='top' points='0,100 100,0 100,100' />

          <ContentSection section={COPY.directory} asset={directory} asset2nd={search} haslocalParallax={
            <ShardGroup items={GROUP2} />
          }
          />
          <Divider position='bottom' points='0,0 100,0 100,100' />
        </div>

        <div className="section">
          <ContentSection section={COPY.studioCard} asset={studioCard} inverse />

          <ShardGroup items={GROUP3} />
        </div>

        <div className="section section--black">
          <Divider position='top' points='0,100 100,0 100,100' />
          <ContentSection section={COPY.cv20} asset={bio} haslocalParallax={
            <ShardGroup items={GROUP4} />
          }/>

          <ContentSection section={COPY.stats} asset={stats} inverse />
          <Divider position='bottom' points='0,0 100,0 100,100' />
        </div>

        <div className="section section--cropped">
          <CentreSection section={COPY.footer} formId={2} />

          <ShardGroup items={GROUP6} isLowered />
          <div className='footer'>
            <a href={COPY.footer.link} className="p">Privacy Policy</a>
          </div>
        </div>

      </ParallaxProvider>
    </div>
  </div>
);

export default App;
