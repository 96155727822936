// import React, {Component} from 'react';
// import { FSS } from '../../lib/fss';

// class Background extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       canvas: {
//         height: 2000,
//         width: 2000
//       },
//     };

//     this.el = document.getElementById('root');
//   }

//   componentDidMount() {
//     const container = this.el;
//     const renderer = new FSS.CanvasRenderer();
//     const scene = new FSS.Scene();
//     const light = new FSS.Light('#111122', '#FF0022');
//     const geometry = new FSS.Plane(600, 400, 6, 4);
//     const material = new FSS.Material('#FFFFFF', '#FFFFFF');
//     const mesh = new FSS.Mesh(geometry, material);
//     let now;
//     const start = Date.now();

//     function initialise() {
//       scene.add(mesh);
//       scene.add(light);
//       container.appendChild(renderer.element);
//       window.addEventListener('resize', resize);
//       renderer.element.width = window.innerWidth;
//       renderer.element.height = window.innerHeight;
//     }

//     function resize() {
//       renderer.setSize(container.offsetWidth, container.offsetHeight);
//     }

//     function animate() {
//       now = Date.now() - start;
//       light.setPosition(300*Math.sin(now*0.001), 200*Math.cos(now*0.0005), 60);
//       renderer.render(scene);
//       requestAnimationFrame(animate);
//     }

//     initialise();
//     resize();
//     animate();
//   }

//   render () {
//     return (
//       <div className='canvas-container'>

//       </div>
//     );
//   }
// }

// export default Background;

import { useLayoutEffect } from 'react';
import { useController } from 'react-scroll-parallax';

const ParallaxCache = () => {
  const { parallaxController } = useController();

  useLayoutEffect(() => {
      const handler = () => parallaxController.update();
      window.addEventListener('load', handler);
      return () => window.removeEventListener('load', handler);
  }, [parallaxController]);

  return null;
};

export default ParallaxCache;
