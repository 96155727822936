import React, { Component, Fragment } from "react";
import Loader from "../Loader";
import "./styles.css";

const apigClientFactory = require("aws-api-gateway-client").default;
const config = {
  invokeUrl: process.env.REACT_APP_INVOKE_URL,
  apiKey: process.env.REACT_APP_USER
};
const apigClient = apigClientFactory.newClient(config);

const isSomeEmpty = object =>
  Object.values(object).some(x => x === null || x === "" || x.length === 0);

const DONE = "DONE";
const INCOMPLETE = "INCOMPLETE";
const SENDING = "SENDING";
const ERROR = "ERROR";

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: "",
        email: "",
        entity: ""
      },
      status: undefined,
      activeEntity: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));

    this.animateRadioToggle(event);
  }

  animateRadioToggle = event => {
    if (event.target.type !== "radio") return false;

    const clicked = event.target.id;
    if (this.state.activeEntity === clicked) {
      this.setState({ activeEntity: "" });
    } else {
      this.setState({ activeEntity: clicked });
    }
  };

  handleSubmit(event) {
    event.preventDefault();

    if (isSomeEmpty(this.state.formData)) {
      this.setState(
        prevState => ({
          ...prevState,
          status: INCOMPLETE
        }),
        () => {
          setTimeout(() => {
            this.setState({ status: undefined });
          }, 500);
        }
      );

      return;
    }

    this.setState({
      status: SENDING
    });
    const pathParams = {};
    const pathTemplate = "/clients";
    const method = "POST";
    const additionalParams = {
      headers: {
        "Content-Type": "application/json"
      },
      queryParams: {}
    };

    const body = {
      ...this.state.formData
    };

    apigClient
      .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then(() => {
        this.setState({
          status: DONE
        });
      })
      .catch(result => {
        this.setState({
          status: ERROR
        });
        console.error("HTML error", result);
      });
  }

  render() {
    const { status, formData, activeEntity } = this.state;

    const appendFormID = entity => entity + this.props.formId;
    return (
      <div className="form-area">
        <div className="wrap-blur">
          <form onSubmit={this.handleSubmit} style={{ position: "relative" }}>
            {status === ERROR ? (
              <p>Sorry there has been error in submitting your request</p>
            ) : status === DONE ? (
              <p>Thank you for signing up. Follow up email TBC</p>
            ) : (
              <Fragment>
                <div
                  className={
                    "field field--radio" +
                    (this.state.status === "INCOMPLETE" ? " field--shake" : "")
                  }
                >
                  <div className="switch">
                    <input
                      type="radio"
                      id={appendFormID("studio")}
                      className="input--radio"
                      name="entity"
                      value="Studio"
                      onChange={this.handleInputChange}
                    />

                    <label htmlFor={appendFormID("studio")}>Studio</label>

                    <input
                      type="radio"
                      id={appendFormID("creator")}
                      className="input--radio"
                      name="entity"
                      value="Creator"
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor={appendFormID("creator")}>Creator</label>

                    <span
                      style={
                        this.state.formData.entity.length === 0
                          ? { background: "rgba(0,0,0,0.3)", color: "#333" }
                          : {}
                      }
                      aria-hidden="true"
                      className={
                        activeEntity === appendFormID("creator") ? "right" : ""
                      }
                    ></span>
                  </div>
                </div>

                <div className="form">
                  <div className="field">
                    <input
                      type="text"
                      className="input"
                      name="name"
                      placeholder="Full name"
                      value={formData.name}
                      onChange={this.handleInputChange}
                      required
                    />
                  </div>

                  <div className="field">
                    <input
                      type="email"
                      className="input"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={this.handleInputChange}
                      required
                    />
                  </div>

                  <div className="field">
                    {status === "SENDING" ? (
                      <Loader />
                    ) : (
                      <input
                        type="submit"
                        className={
                          "button" +
                          (isSomeEmpty(formData) ? " button--disabled" : "")
                        }
                        value="Sign Up"
                        // disabled={isSomeEmpty(formData)}
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default Form;
