import blurtiny1 from '../../assets/images/shards/blurtiny1.svg';
import blurtiny2 from '../../assets/images/shards/blurtiny2.svg';
import blurtiny3 from '../../assets/images/shards/blurtiny3.svg';
import blurtiny4 from '../../assets/images/shards/blurtiny4.svg';

import small1 from '../../assets/images/shards/small1.svg';
import small2 from '../../assets/images/shards/small2.svg';
import small3 from '../../assets/images/shards/small3.svg';

import medium1 from '../../assets/images/shards/medium1.svg';
import medium2 from '../../assets/images/shards/medium2.svg';
import medium3 from '../../assets/images/shards/medium3.svg';
import large1 from '../../assets/images/shards/large1.svg';
import large2 from '../../assets/images/shards/large2.svg';
import orange1 from '../../assets/images/shards/orange1.svg';
import orange2 from '../../assets/images/shards/orange2.svg';
import orange3 from '../../assets/images/shards/orange3.svg';

export const GROUP1 = [
  {
    id: 1,
    svg: blurtiny1,
    y: ['150px', '-100px'],
    x: ['0', '0']
  },
  {
    id: 22,
    svg: small2,
    y: ['950px', '-100px'],
    x: ['0', '0']
  },
  {
    id: 24,
    svg: blurtiny2,
    y: ['250px', '150px'],
    x: ['0', '0']
  },
  {
    id: 26,
    svg: small1,
    y: ['200px', '-400px'],
    x: ['0', '0']
  },
  {
    id: 2,
    svg: blurtiny1,
    y: ['-250px', '-200px'],
    x: ['0', '0']
  },
  {
    id: 3,
    svg: large2,
    y: ['500px', '200px'],
    x: ['0', '0']
  },
  {
    id: 4,
    svg: blurtiny4,
    y: ['-50px', '-350px'],
    x: ['0', '0']
  },
  {
    id: 6,
    svg: large1,
    y: ['500px', '-150px'],
    x: ['0', '0']
  },
  {
    id: 7,
    svg: blurtiny1,
    y: ['-350px', '-750px'],
    x: ['0', '0']
  },
  {
    id: 8,
    svg: medium1,
    y: ['1000px', '-50px'],
    x: ['0', '0']
  },
  {
    id: 80,
    svg: blurtiny3,
    y: ['1450px', '-450px'],
    x: ['0', '0']
  },
  {
    id: 9,
    svg: small2,
    y: ['500px', '-1100px'],
    x: ['0', '0']
  },
  {
    id: 10,
    svg: blurtiny3,
    y: ['800px', '-10px'],
    x: ['0', '0']
  },
  {
    id: 12,
    svg: medium2,
    y: ['200px', '-250px'],
    x: ['0', '0']
  },
  {
    id: 13,
    svg: small1,
    y: ['200px', '-500px'],
    x: ['0', '0']
  }
];

export const GROUP2 = [
  {
    id: 1,
    svg: orange1,
    y: ['650px', '-100px'],
    x: ['-70px', '-70px'],
  },
  {
    id: 2,
    svg: orange2,
    y: ['0px', '-250px'],
    x: ['100px', '100px'],
  }
];

export const GROUP3 = [
  {
    id: 1,
    svg: blurtiny1,
    y: ['150px', '-100px'],
    x: ['0', '0']
  },
  {
    id: 2,
    svg: medium1,
    y: ['50px', '-200px'],
    x: ['0', '0']
  },
  {
    id: 3,
    svg: small2,
    y: ['150px', '-600px'],
    x: ['0', '0']
  },
  {
    id: 4,
    svg: blurtiny2,
    y: ['200px', '-500px'],
    x: ['0', '0']
  },
  {
    id: 5,
    svg: small2,
    y: ['500px', '50px'],
    x: ['0', '0']
  },
  {
    id: 6,
    svg: blurtiny3,
    y: ['200px', '-300px'],
    x: ['0', '0']
  },
  {
    id: 7,
    svg: medium1,
    y: ['250px', '-500px'],
    x: ['0', '0']
  },
  {
    id: 8,
    svg: large1,
    y: ['950px', '250px'],
    x: ['0', '0']
  },
  {
    id: 9,
    svg: blurtiny2,
    y: ['350px', '-150px'],
    x: ['0', '0']
  },

  {
    id: 10,
    svg: medium2,
    y: ['450px', '-350px'],
    x: ['0', '0']
  },
  {
    id: 11,
    svg: small1,
    y: ['1050px', '-150px'],
    x: ['0', '0']
  }
];

export const GROUP4 = [
  {
    id: 1,
    svg: orange3,
    y: ['400px', '130px'],
    x: ['-70px', '-70px'],
  },
  {
    id: 2,
    svg: orange2,
    y: ['300px', '-250px'],
    x: ['70px', '70px'],
  }
];

export const GROUP5 = [
  {
    id: 1,
    svg: blurtiny1,
    y: ['150px', '-100px'],
    x: ['0', '0']
  },
  {
    id: 2,
    svg: medium1,
    y: ['-250px', '-800px'],
    x: ['0', '0']
  },
  {
    id: 3,
    svg: small3,
    y: ['150px', '-50px'],
    x: ['0', '0']
  },
  {
    id: 4,
    svg: blurtiny4,
    y: ['-200px', '-250px'],
    x: ['0', '0']
  },
  {
    id: 5,
    svg: small1,
    y: ['150px', '-150px'],
    x: ['0', '0']
  },
  {
    id: 6,
    svg: blurtiny3,
    y: ['950px', '-350px'],
    x: ['0', '0']
  },
  {
    id: 7,
    svg: blurtiny1,
    y: ['-350px', '-750px'],
    x: ['0', '0']
  },
  {
    id: 8,
    svg: medium1,
    y: ['-450px', '-750px'],
    x: ['0', '0']
  },
  {
    id: 9,
    svg: small2,
    y: ['750px', '-100px'],
    x: ['0', '0']
  },
  {
    id: 10,
    svg: blurtiny3,
    y: ['800px', '-10px'],
    x: ['0', '0']
  },
  {
    id: 11,
    svg: medium3,
    y: ['550px', '-150px'],
    x: ['0', '0']
  },
  {
    id: 12,
    svg: medium2,
    y: ['950px', '-50px'],
    x: ['0', '0']
  },
  {
    id: 13,
    svg: small1,
    y: ['650px', '150px'],
    x: ['0', '0']
  }
];

export const GROUP6 = [
  {
    id: 1,
    svg: blurtiny1,
    y: ['100px', '-100px'],
    x: ['0', '0']
  },
  {
    id: 2,
    svg: medium1,
    y: ['500px', '100px'],
    x: ['0', '0']
  },
  {
    id: 3,
    svg: small3,
    y: ['350px', '-50px'],
    x: ['0', '0']
  },
  {
    id: 4,
    svg: blurtiny4,
    y: ['300px', '-300px'],
    x: ['0', '0']
  },
  {
    id: 5,
    svg: small1,
    y: ['750px', '-100px'],
    x: ['0', '0']
  },
  {
    id: 6,
    svg: blurtiny3,
    y: ['50px', '-50px'],
    x: ['0', '0']
  },

  {
    id: 8,
    svg: medium1,
    y: ['300px', '-100px'],
    x: ['0', '0']
  },
  {
    id: 9,
    svg: small2,
    y: ['400px', '100px'],
    x: ['0', '0']
  },
  {
    id: 10,
    svg: blurtiny3,
    y: ['10px', '-300px'],
    x: ['0', '0']
  },
  {
    id: 11,
    svg: medium3,
    y: ['550px', '-10px'],
    x: ['0', '0']
  },
  {
    id: 13,
    svg: small1,
    y: ['0px', '-200px'],
    x: ['0', '0']
  }
];
