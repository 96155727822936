export const COPY = {
  fold: {
    title: 'Explore the Games Industry with ',
    company1: 'Studio',
    company2: 'Quest',
    text: `A platform for the studios, publishers and individual contributors to the global gaming ecosystem.`,
    security: `We take the handling of all data very seriously. Our services leverage the best practices for security 
      and scalability across our infrastructure. No third parties. No spam. Unsubscribe anytime.`
  },
  directory: {
    title: 'A platform for Games Industry professionals and enthusiasts',
    text: `We built a comprehensive index of the gaming ecosystem from the ground up, to help professional
      developers and followers navigate the world of interactive entertainment. It's designed for those
      who want a better experience.`,
    text2: '',
  },
  studioCard: {
    title: 'Studio Showcase',
    text: `People care and want to know about company culture. Feature yours on a dedicated Studio Showcase
      page so people can discover what games you make, who makes them and how it's done.`,
    text2: `We enable a slick employer branding and engagement experience, offering
      valuable and honest insight into how your studio works.`,
  },
  cv20: {
    title: 'CV 2.0: A game-changing way to show off who you are',
    text: `Beautifully designed with enriched profiles so you won’t ever need to write a CV again.
      Follow your favourite studios & share hooks to your social networks, shipped titles & personal projects.`,
    text2: `Integrate your portfolio, github or demo reel. Get access to skills & e-learning courses.
        Level-up your Creator Profile & let your creative output do the talking.`,
  },
  stats: {
    title: 'User-centric design, with a beautiful interface',
    text: `StudioQuest is gorgeous. Refreshingly intuitive. And comes with advanced features to reinvent the
      way games creators and studios shine the spotlight on and engage with each other.`,
    text2: '',
  },
  form: {
    error: 'Sorry there has been error in submitting your request',
    done: 'Thank you for signing up. Follow Up Email - TBC'
  },
  footer: {
    title: 'The Gateway to the Gaming Industry',
    text: `Built for those who want a better experience`,
    link: `https://static1.squarespace.com/static/5536e307e4b0c13e03faba46/t/5b049bd70e2e72f5182be5a1/
    1527028695278/Studiotypes+Privacy+Policy+%28May+2018%29.pdf`,
    security: `We take very seriously the handling of all data. Our services and databases are secured and authenticated
      using the latest cloud infrastructure best practices. No third parties. No spam. Unsubscirbe anytime.`
  },
}
