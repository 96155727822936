import React from 'react';
import { Parallax } from 'react-scroll-parallax';

import ParallaxCache from '../ParallaxCache';

const ShardGroup = ({items, isLowered}) => (
  <div className={isLowered ? 'vertical vertical--lowered' : 'vertical'}>
    <ParallaxCache />

    <div className='elements'>
      {items.map((shard, i) => (
        <Parallax key={`${shard.id}${i}}`} x={shard.x} y={shard.y}>
          <img src={shard.svg} alt={shard.svg} />
        </Parallax>
      ))}
    </div>
  </div>
)

export default ShardGroup;
