import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';

import './styles.css';

const ContentSection = ({section, asset, inverse, haslocalParallax, asset2nd}) => (
  <div className="section__flex flexcontainer">
    {inverse && <Fade left><div className="flexcontainer__item">
      <div className="flexcontainer__item--asset">
        <img src={asset} alt="asset" className='asset-image' />
      </div>
    </div>
    </Fade>
    }

    <div className="flexcontainer__item">
      <Fade right>
        <Fragment>
        <h2 className="title">{section.title}</h2>

        {section.text.length > 0 &&
          <p className={section.text2.length > 0 ? 'p p--large' : 'p'}>{section.text}</p>}
        {section.text2.length > 0 && <p className="p">{section.text2}</p>}
        </Fragment>
      </Fade>
    </div>

    {!inverse && asset && <div className="flexcontainer__item">
      <Fade right>
        <div className={asset2nd ? '' : 'flexcontainer__item--asset'}>
          {/* {
            asset2nd ? <img style={{
              margin: '0',
              top: '31px',
              zIndex: '3',
              boxShadow: '0px 0px 10px 5px rgba(255, 177, 62, 0.7)',
              position: 'absolute',
            }}
            src={asset2nd} alt="asset" className='asset-image' /> : null
          } */}

          <img src={asset} alt="asset" className='asset-image' />
        </div>
      </Fade>
      {haslocalParallax && haslocalParallax}
    </div>}
  </div>
)

export default ContentSection;
