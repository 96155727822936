import React from 'react';
import Form from '../Form';

const CentreSection = ({section, formId}) => (
  <div className="flexcontainer--single-column section__flex">
    <div className="flexcontainer__item">
      <h2 className="title--large">{section.title}
      <b>{section.company1}</b>
      {section.company2}
      </h2>

      <p className="p"><b>{section.text}</b></p>
    </div>

    <Form formId={formId} />
    
    <p className="p p--small">{section.security}</p>
  </div>
);

export default CentreSection;